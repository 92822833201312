import { Vue } from 'vue-property-decorator'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  silentFallbackWarn: true,
})

export default i18n
