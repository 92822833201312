import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UIcon extends Vue {
  @Prop({ type: String, required: true })
  name: string

  @Prop({ type: Boolean, required: false, default: false })
  colored: boolean

  mounted() {
    return true
  }
}
