import { isUrl } from '@userbot/helpers'

export default {
  getMessage: () => {
    return 'The url is not a valid url'
  },
  validate: (value: string) => {
    // check if is added mailto: or something similar
    if (!value.match(/^[a-zA-Z]+:/)) {
      return isUrl(value)
    }
    return true
  },
}
