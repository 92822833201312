import { Vue, Component, Prop, Watch, Ref, Emit } from 'vue-property-decorator'
import * as VeeValidate from 'vee-validate'
import UrlValidationRule from './rules/url'
import IMask, { InputMask } from 'imask'
import { Key } from '@userbot/helpers'
import { TranslateResult } from 'vue-i18n'
import uniqueId from 'lodash/uniqueId'
import { isUrl } from '@userbot/helpers'
import './validation-rules'
import autosize from 'v-autosize/dist/plugin'
import i18n from '../../../i18n'
import componentMessages from './messages'
const locale = i18n.locale

import { Popover } from 'uiv'

Vue.use(autosize)

const VeeValidateLocalize = VeeValidate.localize
const ValidationProvider = VeeValidate.ValidationProvider

Vue.use(VeeValidate as any)
VeeValidate.extend('url', UrlValidationRule)

Vue.mixin({
  localize(localeName: string) {
    // localize your app here, like i18n plugin.
    // asynchronously load the locale file then localize the validator with it.
    import(`vee-validate/dist/locale/${localeName}.json`).then(res => {
      VeeValidateLocalize(locale, res)
    })
  },
} as any)

// eslint-disable-next-line @typescript-eslint/no-var-requires
const messages = require('vee-validate/dist/locale/' + locale + '.json')
VeeValidateLocalize(locale, messages)

import * as formats from './format-rules'

@Component({
  components: {
    Popover,
    ValidationProvider,
  },
  i18n: {
    messages: componentMessages,
  },
})
export default class UInput extends Vue {
  @Ref('input')
  refInput: HTMLInputElement

  @Ref('validator')
  refValidator: any

  @Prop({ required: true })
  value: any

  @Prop({ required: false, default: () => [] })
  options: Array<{ value: string }>

  @Prop({ type: String, required: false, default: 'text' })
  type:
    | 'number'
    | 'url'
    | 'password'
    | 'date'
    | 'text'
    | 'select'
    | 'email'
    | 'recipientcode'
    | 'taxcode'
    | 'textarea'

  @Prop({ type: String, required: false, default: '' })
  label: string

  @Prop({ type: String, required: false, default: '' })
  placeholder: string

  @Prop({ type: String, required: false, default: () => '' })
  name: string

  @Prop({ type: Boolean, required: false, default: false })
  centered: boolean

  @Prop({ type: Boolean, required: false, default: false })
  first: boolean

  @Prop({ type: [String, Array, RegExp], required: false, default: '' })
  format: string

  @Prop({ type: Boolean, required: false, default: false })
  required: boolean

  @Prop({ type: Number, required: false, default: null })
  max: number | null

  @Prop({ type: Number, required: false, default: null })
  min: number | null

  @Prop({ type: Number, required: false, default: null })
  exact: number | null

  @Prop({ type: Boolean, required: false, default: false })
  error: boolean

  @Prop({ type: String, required: false, default: '' })
  prependIcon: string

  @Prop({ type: String, required: false, default: '' })
  appendIcon: string

  @Prop({ type: Boolean, required: false, default: false })
  small: boolean

  @Prop({ type: Boolean, required: false, default: false })
  disabled: boolean

  @Prop({ type: Boolean, required: false, default: false })
  autocomplete: boolean

  @Prop({ type: String, required: false, default: '' })
  tooltip: string

  @Prop({
    type: Function,
    required: false,
    default: () => {
      return true
    },
  })
  asyncCheck: (input: this) => Promise<boolean | { error: string }>

  @Prop({
    type: String,
    required: false,
    default: 'lazy',
  })
  validationMode: 'lazy' | 'passive' | 'aggressive' | 'eager'

  @Prop({ type: Boolean, required: false, default: false })
  readonly: false

  @Watch('value', { immediate: true })
  onValueChange(val: any, oldVal: any) {
    if (val !== oldVal) {
      if (
        this.type !== 'recipientcode' ||
        (this.type === 'recipientcode' && val !== '0000000')
      ) {
        if (this.type === 'taxcode') {
          val = val?.toUpperCase()
        }
        this.text = val
      }
    }
  }

  @Watch('error', { immediate: true })
  onErrorChange(val: boolean) {
    this.forceError = val
  }

  @Watch('disabled', { immediate: true })
  onDisabledChange(val: boolean) {
    this.disabledStatus = val
  }

  @Watch('readonly', { immediate: true })
  onReadonlyChange(val: boolean, oldVal: undefined | boolean) {
    if (val !== oldVal) {
      this.readonlyStatus = val
    }
  }

  @Watch('prependIcon', { immediate: true })
  onPrependIconChange(val: string) {
    this.iconPrepended = val
  }

  @Watch('appendIcon', { immediate: true })
  onAppendIconChange(val: string) {
    this.iconAppended = val
  }

  @Watch('text')
  onTextChange(val: string, oldVal: string) {
    if (typeof val === 'undefined') {
      val = ''
    }
    if (val !== oldVal) {
      this.onTextInputChange(val)
    }
  }

  get validationRules(): any {
    const rules: {
      required?: boolean
      email?: boolean
      numeric?: boolean
      url?: boolean
      min?: number
      max?: number
    } = {}
    if (this.requiredValue) rules.required = true
    if (this.type === 'email') rules.email = true
    if (this.type === 'number') rules.numeric = true
    if (this.type === 'url') rules.url = true
    if (this.minValue) rules.min = this.minValue
    if (this.max) rules.max = this.max
    if (this.exact) {
      rules.max = this.exact
      rules.min = this.exact
    }
    return rules
  }

  get formatRule(): string {
    return this.format || (formats as any)[this.type]
  }

  get validType(): string {
    switch (this.type) {
      case 'email':
      case 'number':
      case 'password':
      case 'date':
        return this.type

      default:
        return 'text'
    }
  }

  get validationProviderName() {
    if (this.name) {
      return this.name
    }

    if (this.label) {
      return this.label.toLowerCase()
    }

    return uniqueId('uinput')
  }

  text = ''
  forceError = false
  allErrors: Array<string | TranslateResult> = []
  dirty = false
  disabledStatus = false
  minValue = 0
  requiredValue = false
  iconPrepended = ''
  iconAppended = ''
  async = false
  loading = false
  readonlyStatus = false
  mask: InputMask<any> | null = null
  focused = false

  mounted() {
    this.async = !!this.asyncCheck

    this.preloadSelectedSelectOption()

    if (this.formatRule) {
      this.mask = IMask(this.refInput, {
        mask: this.formatRule,
        min: this.min,
        max: this.max,
      })
    }
  }

  async onTextInputChange(value: string) {
    await this.updateText(value)
  }

  private async onTextareaInputChange(inputEvent: InputEvent) {
    this.onTextInputChange((inputEvent?.target as any).value || '')
  }

  @Emit('input')
  private async updateText(value: string) {
    await this.refValidator.validate()
    const text = await this.checkValue(value || undefined)
    if (this.mask) {
      // TODO: Writing fast overlap letters
      this.mask.value = text ? text : ''
      this.text = this.mask.value
    } else {
      if (this.text !== text) {
        this.text = text
      }
    }
    this.dirty = true
    return this.text
  }

  focus() {
    if (this.refInput) {
      this.refInput.focus()
    }
  }

  @Emit('blur')
  async onBlur(e: Event) {
    /* if (this.type === 'url') {
      const value = this.checkUrlValue(this.text?.trim())
      this.$emit('input', value)
    } */
    this.focused = false
    return e
  }

  @Emit('focus')
  onFocus(e: Event) {
    this.focused = true
    return e
  }

  @Emit('keydown')
  onKeydown(e: KeyboardEvent) {
    return e
  }

  @Emit('keyup')
  onKeyup(e: KeyboardEvent) {
    if (Key.isEnter(e)) {
      this.$emit('enter', e)
    }
    return e
  }

  /**
   * check if input is standard text input
   * @return {boolean}
   */
  isStandardTextType() {
    return this.type !== 'textarea' && this.type !== 'select'
  }

  /**
   * required
   * @return {boolean}
   */
  isRequired() {
    return this.requiredValue
  }

  /**
   * check if input is valid
   * @return {boolean}
   */
  isValid() {
    if (this.hasErrors()) {
      return false
    }

    if (this.isRequired()) {
      return !!this.text
    }

    return true
  }

  @Emit('input')
  reset() {
    this.refValidator.reset()
    this.text = ''
    return this.text
  }

  /**
   * check value format is correct
   */
  async checkValue(value: any) {
    if (value) {
      if (typeof value !== 'string') {
        return value
      } else if (value.length > this.validationRules.max) {
        value = value.slice(0, this.validationRules.max)
      }
      if (this.type === 'number') {
        if (this.max && value > this.max) {
          value = this.max
        }
        if (this.minValue && value < this.minValue) {
          value = this.minValue
        }
      }

      await this.checkAsyncValidation()
    }
    return value
  }

  async validate() {
    await this.refValidator.validate()
    return this.checkValue(this.text)
  }

  private checkUrlValue(value: string | null) {
    if (value) {
      if (!value.match(/^[a-zA-Z]+:/)) {
        value = `https://${value}`

        if (!isUrl(value)) {
          this.setErrors([])
        }
      }
      return value
    }
    return ''
  }

  async checkAsyncValidation() {
    if (this.isValid() && this.asyncCheck) {
      this.loading = true
      const res = await this.asyncCheck(this)
      if (res && typeof res !== 'boolean') {
        if ((res as any).error) {
          this.allErrors.push((res as any).error)
        }
      }
      this.loading = false
    }
  }

  private getHTMLClass({ errors }: { errors: string[] }) {
    this.allErrors = errors ? errors : []
    return {
      'u-input--empty': !this.text,
      'u-input--select': this.type === 'select',
      'u-input--error': this.hasErrors() || this.forceError,
      'u-input--centered': this.centered,
      'u-input--first': this.first,
      'u-input--small': this.small,
      'u-input--focus': this.focused,
      'u-input--with-icon-appended':
        this.iconAppended || this.$slots['appended-icon'],
      'u-input--with-icon-prepended':
        this.iconPrepended || this.$slots['prepended-icon'],
      'u-input--disabled': this.disabledStatus,
      'u-input--label': this.label || this.$slots.label,
    }
  }

  setErrors(errors: Array<string | TranslateResult> = []) {
    this.refValidator.setErrors(errors)
    return this
  }

  hasErrors() {
    return this.allErrors.length > 0
  }

  /**
   * preselect first option if placeholder is not present
   * @private
   */
  private preloadSelectedSelectOption() {
    this.requiredValue = this.required

    if (this.min !== null) {
      this.minValue = this.min
    }

    if (this.type === 'select') {
      if (!this.text && !this.placeholder) {
        this.text = this.options[0]?.value
      }
    }

    if (this.type === 'password') {
      this.requiredValue = true
      if (this.min) {
        this.minValue = this.min
        return
      }
      this.minValue = 8
      return
    }
  }
}
