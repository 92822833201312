export default {
  it: {
    required: 'richiesto',
  },
  en: {
    required: 'required',
  },
  fr: {
    required: 'obligatoire',
  },
}
