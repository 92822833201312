// TODO: verificare che il type di tipo upgrade sia utilizzato
import { Vue, Prop, Component } from 'vue-property-decorator'
import * as Waves from 'node-waves'

type Color = 'neutral' | ''

let wavesInitialized = false

@Component
export default class UButton extends Vue {
  @Prop({ type: String, required: false, default: 'button' })
  tag: string

  @Prop({ type: Boolean, required: false, default: false })
  disabled: boolean

  @Prop({ type: [String, Array], required: false, default: '' })
  variant: Color | Color[]

  @Prop({ type: [String, Array], required: false, default: 'button' })
  type: string | string[]

  @Prop({ type: String, required: false, default: '' })
  icon: string

  @Prop({ type: Boolean, required: false, default: false })
  rounded: boolean

  @Prop({ type: Boolean, required: false, default: false })
  reversed: boolean

  @Prop({ type: Boolean, required: false, default: false })
  small: boolean

  @Prop({ type: Boolean, required: false, default: false })
  lamp: boolean

  @Prop({ type: Boolean, required: false, default: false })
  block: boolean

  @Prop({ type: Boolean, required: false, default: false })
  submit: boolean

  @Prop({ type: Boolean, required: false, default: true })
  waves: boolean

  mounted() {
    if (!wavesInitialized) {
      Waves.init()
      wavesInitialized = true
    }

    if (this.waves) {
      this.bindWavesClick()
    }
  }

  emitClick(e: MouseEvent) {
    if (this.disabled) return
    this.$emit('click', e)
  }

  getType(): string {
    if (this.tag === 'button') {
      return this.submit ? 'submit' : 'button'
    }
    return ''
  }

  getBtnClass(): string[] {
    let _c: string[] = []
    if (this.rounded) _c.push('u-button--rounded')

    _c = _c.concat(this.getBtnClassFromType())
    _c = _c.concat(this.getBtnClassFromVariant())

    if (this.icon && !this.$slots.default) {
      _c.push('u-button--icon')
    }

    if (this.block) {
      _c.push('u-button--block')
    }

    if (this.small) {
      _c.push('u-button--small')
    }

    if (this.reversed) {
      _c.push('u-button--reversed')
    }

    if (this.lamp) {
      _c.push('u-button--lamp')
    }

    return _c
  }

  getBtnClassFromVariant(): string[] {
    const variants: string[] = []
    if (Array.isArray(this.variant)) {
      this.variant.forEach(item => variants.push(`u-button--${item}`))
    } else {
      variants.push(`u-button--${this.variant}`)
    }

    return variants
  }

  getBtnClassFromType() {
    const types: string[] = []

    if (Array.isArray(this.type)) {
      this.type.forEach(type => {
        types.push(this._getBtnClassFromSingleType(type))
      })
    } else {
      types.push(this._getBtnClassFromSingleType(this.type))
    }
    return types
  }

  private _getBtnClassFromSingleType(type: string): string {
    switch (type) {
      case 'link':
        return 'u-button--pure'

      default:
        return `u-button--${type}`
    }
  }

  bindWavesClick() {
    if (this.type === 'button' || this.type === 'primary') {
      Waves.attach(this.$el as HTMLElement, [
        'waves-button',
        'waves-float',
        'waves-light'
      ])
    } else {
      Waves.attach(this.$el as HTMLElement, ['waves-button', 'waves-float'])
    }
  }
}
