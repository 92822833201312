import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Key } from '@userbot/helpers'
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

@Component
export default class UModal extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  value: boolean

  @Prop({ type: Boolean, required: false, default: false })
  video: boolean

  @Prop({ type: String, required: false, default: '' })
  title: string

  @Prop({ type: String, required: false, default: '' })
  icon: string

  @Prop({ type: String, required: false, default: '' })
  image: string

  @Prop({ type: Number, required: false })
  width: number

  @Prop({ type: Boolean, required: false, default: false })
  centered: boolean

  @Prop({ type: Boolean, required: false, default: false })
  verticalCentered: boolean

  @Prop({ type: String, required: false, default: 'sm' })
  size: string

  @Prop({ type: [String, Array], required: false, default: '' })
  dialogClass: string | []

  @Prop({ type: Boolean, required: false, default: false })
  widescreen: boolean

  @Prop({ type: Boolean, required: false, default: false })
  inline: boolean

  opened = false
  isOnContentBox = false
  backdropVisibility = false
  cancelled = false

  @Watch('value', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (!!val !== !!oldVal) {
      if (!val) {
        this._closeWithoutEvents()
      } else {
        this._openWithoutEvents()
      }
    }
  }

  mounted() {
    document.addEventListener('keyup', this.handleEscKey)
  }

  destroyed() {
    document.removeEventListener('keyup', this.handleEscKey)
  }

  toggle(e: MouseEvent) {
    !this.opened ? this.open() : this.close()
    this.$emit('input', e)
  }

  /**
   * @description close modal and emit events
   */
  close() {
    this._closeWithoutEvents().then(() => {
      this.$emit('input', false)
    })
  }

  /**
   * @description close modal without calling events
   * @return {Promise<void>}
   */
  private async _closeWithoutEvents() {
    document.body.classList.remove('u-modal--opened')
    this.backdropVisibility = false

    setTimeout(() => {
      this.opened = false
      Promise.resolve()
    }, 300)
  }

  open() {
    this._openWithoutEvents()
    this.$emit('input', true)
    this.$emit('show', true)
  }

  /**
   * @description open without events
   */
  private _openWithoutEvents() {
    document.body.classList.add('u-modal--opened')
    this.opened = true
    this.$nextTick(() => {
      this.backdropVisibility = true
    })
  }

  closeOnOutsideClick(e: any) {
    if (!this.isOnContentBox) {
      if (e.target.tagName !== 'OPTION' && e.target.tagName !== 'SELECT') {
        // TODO: firefox causa problemi. E' da sistemare.
        this.cancel()
        this.close()
      }
    }
  }

  cancel() {
    if (!this.cancelled) {
      this.cancelled = true
      this.$emit('cancel')
    }
  }

  /**
   * @description emit show event after modal appear
   */
  afterEnter() {
    this.$emit('show')
  }

  /**
   * @description emit hide event after modal disappear
   */
  afterLeave() {
    this.$emit('hide')
  }

  /**
   * @description handle ESC key press
   * @param e
   */
  handleEscKey(e: KeyboardEvent) {
    if (this.opened && Key.isEsc(e)) {
      this.close()
      this.cancel()
    }
  }

  getHtmlClass(): string[] {
    const dialogClass = Array.isArray(this.dialogClass)
      ? this.dialogClass
      : this.dialogClass.split(' ')
    const array = dialogClass.concat([`u-modal--${this.size}`])

    if (this.centered) {
      array.push('u-modal--centered')
    }

    if (this.verticalCentered) {
      array.push('u-modal--vertical-centered')
    }

    if (this.widescreen) {
      array.push('u-modal--widescreen')
    }

    if (this.video) {
      array.push('u-modal--video')
    }

    return array
  }

  onClickClose() {
    this.close()
    this.cancel()
  }

  private isHeaderEmpty() {
    return (
      !this.title &&
      !this.icon &&
      !this.image &&
      !this.$slots['title'] &&
      !this.$slots['header']
    )
  }
}
