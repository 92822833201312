import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class USwitch extends Vue {
  @Prop({ type: Boolean, required: false })
  value: boolean

  @Prop({ type: String, required: false, default: '' })
  color: string

  @Prop({ type: Boolean, required: false, default: false })
  disabled: boolean

  @Watch('value', { immediate: true })
  onValueChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.checked = val
    }
  }

  @Watch('disabled', { immediate: true })
  onDisabledChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.localDisabled = val
    }
  }

  localDisabled = false
  checked = false

  toggle(): void | boolean {
    if (!this.localDisabled) {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  }
}
